import './details/details';
import './details/details-package';
import './details/details-accommodation';
import './details/details-travel';

$(() => {

    $('.btn-share').on('click', e => {
        const $target = $(e.currentTarget);
        const $shareWrapper = $target.parents('.share-wrapper');
        let shareShown = ! $shareWrapper.hasClass('show');
        $shareWrapper.toggleClass('show', shareShown);
    });

    $('[data-bs-tab^=availability-tab]').on('click', e => {
        const $target = $(e.currentTarget);
        const unitId = $target.attr('data-unitid');
        $('#unitModal-' + unitId).find('[id=' + $target.attr('data-bs-tab') +']').tab('show');
    });

    $('.summary-large-show').on('click', e => {
        const $target = $(e.currentTarget);
        const $summary = $('.summary-large');
        const isShowen = $summary.hasClass('show');
        $summary.toggleClass('show', ! isShowen);
        $target.text(isShowen ? $target.attr('data-show') : $target.attr('data-hide'));
    });

    //let lastScroll = window.scrollY;
    const $detailsNav = $('#details-nav');

    $(window).on('scroll', e => {
        //$detailsNav.toggleClass('show', window.scrollY > lastScroll);
        $detailsNav.toggleClass('show', window.scrollY > 200);
        //window.scrollY == 0 && $detailsNav.toggleClass('show', false);
        //lastScroll = window.scrollY;
    });
});