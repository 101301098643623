import functions from '../functions.js';
import Slider from "../components/Slider";
import { details } from './details';

$(() => {
    detailsTravel.init();
})

const detailsTravel = {
    $details: null,
    $form: null,
    form: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
    },
    init() {

        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');

        if(! this.$details[0] || this.params.objectGroupId != 6) {
            return;
        }

        this.getParams();

        this.params.objectGroupId = this.$details.attr('data-objectgroupid');
        this.params.objectId = this.$details.attr('data-objectid');
        this.params.calcErrorMessages = $('[data-calcErrorMessages]')[0] ? JSON.parse($('[data-calcErrorMessages]').attr('data-calcErrorMessages')) : null;

        if(this.params.guests) {
            $('[name=guests]').val(this.params.guests);
            $('.input-guests')[0]._inputGuests.elemChange($('.input-guests')[0]._inputGuests);
        }

        this.initDepartureCalendar();
        this.setDefaultDeparture();

        this.setGuestsInstances();

        (! this.params.guests || ! this.params.dateFrom) && this.setUnitRates();

        this.getCalculation();

        $('.departure').on('click', e => {
            $('.departure').removeClass('active');

            const $target = $(e.currentTarget);
            $target.addClass('active');
            this.params.dateFrom = $target.attr('data-datefrom');
            $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
            this.params.dateTo = $target.attr('data-dateto');

            this.printDates();

            functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);

        });

        $('.departure-sm').on('click', e => {
            const $target = $(e.currentTarget);
            this.params.dateFrom = $target.attr('data-datefrom');

            functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);

            functions.scrollToElement('#booking', 0);
        });

        $('[name=guests]').on('change', e => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            //this.params.unitId = $target.attr('data-unitid');
            this.params.guests = $target.val();

            functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);
            //this.getCalculation();
        });

        $(window).on('hashchange', e => {
            this.getParams();

            this.setDeparture();
            this.setGuestsInstances();
            (! this.params.guests || ! this.params.dateFrom) && this.setUnitRates();
            this.getCalculation();

            /*$('.unit').each((index, unit) => {
                const $unit = $(unit);
                this.params.unitId = $unit.attr('data-unitid');
                this.params.guests = $unit.find('[name=guests]').val();
                this.getCalculation();
            });*/
        });

        $('.btn-print').on('click', e => {
            const params = $('.btn-print').attr('data-params');

            if(params) {
                const url = location.protocol + '//' + location.hostname + '/services/pdf/' + params;
                window.open(url, '_blank').focus();
            }
        });

    },
    initDepartureCalendar() {
        const $bookingCalendar = $('#booking-calendar-slider');

        if($bookingCalendar[0]) {
            const length = $bookingCalendar.find('.keen-slider__slide').length;
            const perView = 8;
            
            let slidesPerView = length < perView ? length : perView;

            if(length < perView) {
                $('.booking-calendar-wrapper').addClass('col-lg-' + (slidesPerView + 2));
                $('.booking-calendar-wrapper').addClass('mx-auto');
            }
    
            this.slider = new Slider($bookingCalendar, {
                // mode: 'snap',
                //centered: true,
                duration: 200,
                fade: false,
                loop: false,
                controls: false,
                initial: 0,
                arrows: functions.isMobile() || length > slidesPerView,
                slides: {
                    perView: slidesPerView,
                    spacing: 0,
                },
                breakpoints: {
                    '(min-width: 577px) and (max-width: 1024px)': {
                        controls: true,
                        arrows: length > 6,
                        //centered: true,
                        slides: {
                            perView: 6.2,
                        },
                    },
                    '(max-width: 576px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 2.2,
                        },
                    },
                },
            });
        }
    },
    setDefaultDeparture() {
        this.params.dateFrom = ! this.params.dateFrom ? $('[data-datefrom]').attr('data-datefrom') : this.params.dateFrom;
        $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
        this.params.dateTo = $('[data-datefrom=' + this.params.dateFrom + ']').attr('data-dateto');

        this.params.guests = $('[name=guests]').val(); 

        functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);

        this.printDates();
    },
    setDeparture() {
        if(this.params.dateFrom) {
            $('.departure').removeClass('active');
            $('.departure-sm').removeClass('active');

            $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
            this.params.dateTo = $('[data-datefrom=' + this.params.dateFrom + ']').attr('data-dateto');
            
            this.printDates();
        } else {
            this.setDefaultDeparture();
        }
    },
    printDates() {
        $('.date-from').text(functions.formatDate(this.params.dateFrom));
        $('.date-to').text(functions.formatDate(this.params.dateTo));
    },
    setUnitRates() {
        if(this.params.dateFrom) {
            this.loader();

            setTimeout(() => {
                const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + ']');
                const rates = JSON.parse($departure.attr('data-rates'));

                let excludedUnits = [];

                $('.unit').each((index, elem) => {
                    const unitIds = Object.keys(rates);
                    const unitId = $(elem).attr('data-unitid');

                    ! unitIds.includes(unitId) && excludedUnits.push(unitId);
                });

                if(excludedUnits.length) {
                    excludedUnits.forEach(unitId => {
                        const $unit = $('.unit[data-unitid="' + unitId + '"]');
                        $unit.find('.unit-price').addClass('d-none');
                    });
                }

                Object.entries(rates).forEach(item => {
                    const unitId = item[0];
                    const rate = item[1];
    
                    const $unit = $('.unit[data-unitid="' + unitId + '"]');
    
                    $unit.find('.unit-price').removeClass('d-none');

                    $unit.find('.unit-price-old').text(rate.priceWithDiscount ? rate.price : '');
                    $unit.find('.unit-price-total').text(rate.priceWithDiscount ? rate.priceWithDiscount : rate.price);
    
                    $unit.find('.unit-price-old-eur').text(rate.priceWithDiscountEUR ? rate.priceEUR : '');
                    $unit.find('.unit-price-total-eur').text(rate.priceWithDiscountEUR ? rate.priceWithDiscountEUR : rate.priceEUR);
    
                    $unit.find('.fm-discount').toggleClass('d-none', ! rate.discountTitle);
                    $unit.find('.fm-discount-title').text(rate.discountTitle ? rate.discountTitle : '');
                    $unit.find('.fm-discount-valid-until').toggleClass('d-none', ! rate.validUntil);
                    $unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');
                });
            }, 800);
        }
    },
    setGuestsInstances() {
        const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + ']');
        let maxPersonsUnits = $departure.attr('data-maxPersonsUnits') ? JSON.parse($departure.attr('data-maxPersonsUnits')) : null;
        
        if(maxPersonsUnits) {
            Object.entries(maxPersonsUnits).forEach(item => {
                const unitId = item[0];
                const quantity = item[1];
    
                $('.input-guests[data-unitid="' + unitId + '"]').each((i, elem) => {
                    const instance = elem._inputGuests; 
                    instance.max = quantity ? quantity : instance.max;
                    instance.checkUpdateBtns(instance, 'adults');
                });
            });
        }
    },
    getParams() {
        this.params = details.getParams(this.params);
    },
    getCalculation() {

        const calculation = details.getCalculation(this.params);
        if(calculation) {
            this.loader(this.params.unitId ? $('.unit[data-unitid=' + this.params.unitId + ']').find('.unit-spinner') : null);
            calculation
                .done(response => {
                    this.calculationDone(response);
                }).fail(error => {
                    console.log(error);
                });
        } else {

            //this.setPrices($('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']'));

            this.setUnitRates();
            
            $('.btn-booking').toggleClass('d-none', true);
            $('.no-period-label').toggleClass('d-none', false);

            $('.unit-error-container').toggleClass('d-none', true);

            $('.unit-price-total-label').toggleClass('d-none', true);
            $('.unit-price-label').toggleClass('d-none', false);

            $('.unit-price-payment-key').toggleClass('d-none', false);
        }
    },
    calculationDone(response) {
        if(response.status) {
            const data = response.data || [];

            //console.log(data);

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = $('.unit[data-unitid=' + unitId + ']');

                let discountTitle = null;

                if(details.isBookable(unitData)) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);
                    priceOld = +priceOld.toFixed(2);
                    discountTitle = details.getCalcDiscountTitle(calcItems);

                    if(discountTitle) {
                        $unit.find('.unit-price-discount-title').text(discountTitle);
                        $unit.find('.fm-discount-title').text(discountTitle ? discountTitle : '');
                    }

                    $unit.find('.unit-price-old').text(priceOld > total ? currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ',') : '');

                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));

                    this.printDates();
                    
                    /*calcItems.forEach(item => {
                        let title = item.data.text || item.itemTitle;
                        console.log(title);
                        console.log(item.itemTotal);
                        console.log(item.onSpot);
                        console.log(item.optional);
                        console.log(item);
                        console.log('--------------------------------------------------');
                    });*/

                    const $btnBooking = $unit.find('.btn-booking[data-unitId=' + unitId + ']');

                    $btnBooking.text(unitData.booking.status == 'Request' ? $btnBooking.attr('data-requestLabel') : $btnBooking.attr('data-bookingLabel'));

                    if($btnBooking.attr('data-href')) {
                        $btnBooking.attr('data-href',
                            $btnBooking.attr('data-href').split('?')[0] +
                            '?objectGroupId=' + this.params.objectGroupId +
                            '&unitId=' + unitId +
                            '&dateFrom=' + this.params.dateFrom +
                            '&dateTo=' + this.params.dateTo +
                            '&guests=' + this.params.guests +
                            '&addAllOptionals=' + 1
                        );
                    }

                    $btnBooking.on('click', e => {
                        e.preventDefault();
                        location = $(e.currentTarget).attr('data-href');
                    });
                    
                }

                $unit.find('.unit-price-total-label').toggleClass('d-none', ! details.isBookable(unitData));

                $unit.find('.unit-price-label').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-price').toggleClass('d-none', ! details.isBookable(unitData));

                $unit.find('.unit-price-discount-title').toggleClass('d-none', ! details.isBookable(unitData));
                $unit.find('.unit-price-payment-key').toggleClass('d-none', details.isBookable(unitData));

                $unit.find('.fm-discount').toggleClass('d-none', ! details.isBookable(unitData));
                
                $unit.find('.fm-discount-valid-until').toggleClass('d-none', details.isBookable(unitData));
                //$unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');

                $unit.find('.no-period-label').toggleClass('d-none', details.isBookable(unitData));

                $unit.find('.btn-booking').toggleClass('d-none', ! details.isBookable(unitData));

                let error = details.getCalcErrorMessage(this.params.calcErrorMessages, unitData);
                $unit.find('.unit-error-container').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-error-container').text(error);
            });
        }
    },
    loader($target = null) {
        $('.units').addClass('fade');
        $target ? $target.addClass('active') : $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $target ? $target.removeClass('active') : $('.unit-spinner').removeClass('active');
        }, 600);
        setTimeout(() => {
            $('.units').removeClass('fade');
        }, 800);
    }
};
