import functions from '../functions';

$(() => {
    details.init();
})

const details = {
    $details: null,
    $form: null,
    form: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
    },
    init() {

        details.$details = $('.page-type-details');

        if(! details.$details[0]) {
            return;
        }

    },
    getParams(params, defaults = {}) {
        return $.extend({}, params, defaults, functions.getUrlData(true));
    },
    isBookable(data, rateId = null) {
        if(rateId) {
            return (data.booking.status == 'Request' || data.booking.status == 'Reservation') && data.isAvailable && data.calc.status == 'Active';
        }
        return (data.booking.status == 'Request' || data.booking.status == 'Reservation') && data.calc.status == 'Active';
    },
    getCalcErrorMessage(messages, data) {
        if(messages && data.calc.message && data.calc.message.type) {
            return messages[data.calc.message.type] ? messages[data.calc.message.type].replace('{$condition}', data.calc.message.condition_full ? data.calc.message.condition_full : data.calc.message.condition) : null;
        }
        else if (data.message && messages && messages[data.message]) {
            return messages[data.message] ? messages[data.message].replace('{$condition}', data.condition) : null;
        }
        return messages ? messages.general : null;
    },
    getCalculation(params) {

        const objectGroupId = params.objectGroupId;
        const objectId = params.objectId;
        const unitId = params.unitId;
        const dateFrom = params.dateFrom;
        const dateTo = params.dateTo ? params.dateTo : dateFrom;
        const guests = params.guests && params.guests.startsWith('0') ? null : params.guests;
        const dynamicPackage = params.dynamicPackage;
        const flightId = params.flightId;
        const flightUnitId = params.flightUnitId;
        const pricelistId = params.pricelistId;
        const optionals = params.optionals;
        const serviceCode = params.serviceCode ? encodeURIComponent(params.serviceCode) : null;

        if(
            (objectId || unitId)
            && guests 
            && dateFrom 
            && dateTo 
            && objectGroupId
        ) {
            return $.getJSON(
                location.protocol
                + '//'
                + location.hostname
                + '/services/calc/'
                + (unitId ? '?unitId=' + unitId : '?objectId=' + objectId)
                + '&guests=' + guests
                + '&dateFrom=' + dateFrom
                + '&dateTo=' + dateTo
                + '&objectGroupId=' + objectGroupId
                + '&language=' + functions.getLang()
                + (dynamicPackage ? '&dynamicPackage=' + dynamicPackage : '')
                + (flightUnitId ? '&flightUnitId=' + flightUnitId : '')
                + (pricelistId ? '&pricelistId=' + pricelistId : '')
                + (optionals ? '&optionals=' + optionals : '')
                + (serviceCode ? '&serviceCode=' + serviceCode : '')
                + (params.time ? '&time=' + params.time : '')
            );
        } else {
            return null;
        }

    },
    getCalcTotalOld(calcItems) {
        let priceOld = 0;

        calcItems.forEach(item => {
            // popust za djecu ne ulazi u prikaz discount cijene
            if((item.itemType != 'discount' || item.itemDefinitionId == 129) && !item.optional && !item.onSpot) {
                priceOld += item.itemTotal;
            }
        });

        return priceOld;
    },
    getCalcDiscountTitle(calcItems) {

        let discountTitle = null

        calcItems.forEach(item => {
            if(item.itemType == 'discount') {
                discountTitle = item.itemTitle;
                if(item.data && item.data.text) {
                    discountTitle = item.data.text;
                }
            }
        });
        
        return discountTitle;
    },
};

export {
    details,
};
