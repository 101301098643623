import functions from '../functions';
import { list } from './list';
import pagination from '../components/pagination';
import contactRequest from '../contactRequest';

$(() => {
    listFeatured.init();
});

const listFeatured = {
    defaults: {
        _page: 1,
        perPage: 12,
        language: functions.getLang(),
        template: 'featured',
    },
    params: {},
    $listItems: null,
    init() {

        this.$listItems = $('.list-featured');

        if(! this.$listItems[0]) {
            return;
        }

        this.defaults.dynamicPackage = this.$listItems.attr('data-dynamicpackage');
        this.defaults.countryId = this.$listItems.attr('data-countryid');
        this.defaults.destination = this.$listItems.attr('data-destination');
        this.defaults.objectGroupId = this.$listItems.attr('data-objectgroupid');
        this.defaults.tagCategoryId = this.$listItems.attr('data-tagcategoryid');
        this.defaults.tagCategoryIds = this.$listItems.attr('data-tagcategoryids');
        this.defaults.hasArrival = this.$listItems.attr('data-hasarrival');
        
        this.getParams();
        this.find(this.params);

        $('[name="tagCategoryId"]').on('change', e => {
            this.params._page = this.defaults._page;
            this.getParams();
            this.setTagCategoryId();
            //this.find(this.params);
            functions.setUrlData(this.params, true, true, this.defaults);
        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.stopChangeEvents = true;

            functions.scrollToElement(this.$listItems, 100);
            this.find(this.params);
            this.stopChangeEvents = false;
        });

    },
    getParams() {
        this.params = list.getParams(this.params, this.defaults);
    },
    setTagCategoryId() {
        let tagCategoryIds = [];
        $('[name="tagCategoryId"]').each((index, target) => {
            const $target = $(target);
            if($target[0].checked) {
                tagCategoryIds.push($target.val());
            }
        });
        this.params.tagCategoryId = tagCategoryIds.join(",");
    },
    find(params) {
        this.setLoadingMode();
        this.params = params;

        $.get('/services/list/', params).then((response) => {
            this.findDone(response);
            // LOZAD
            window.observer.observe();

            $('.departure').on('click', e => {
                e.preventDefault();

                const $target = $(e.currentTarget);
                let href = $target.attr('data-href');
                href = href.split('#')[0];

                const dateFrom = $target.attr('data-datefrom');
                const dateTo = $target.attr('data-dateto');

                href += '#dateFrom=' + dateFrom + '&dateTo=' + dateTo;
                //location = href; 
                window.open(href, '_blank');
            });

        });
    },
    findDone(response) {
        response = JSON.parse(response);          

        this.unsetLoadingMode();

        this.total = response.total;

        this.$listItems.html(response.html);
        if (this.total != 0) {

            if($('.paginate')[0]) {
                $('.paginate').html(pagination({
                    currentPage: this.params._page,
                    total: this.total,
                    perPage: this.params.perPage,
                }));
            }
        }

        //listResults.getResults(this.total, this.params._page, this.params.perPage);

        this.initComponents();

    },
    setLoadingMode() {
        if(! this.loading) {
            this.loading = true;

            if ( ! $('.spinner').length) {
                $('<div class="spinner d-none"><div class="spinner-border text-secondary" role="status" style="width: 4rem; height: 4rem;"><span class="sr-only">Loading...</span></div></div>').insertBefore('.list-featured');
            }

            $('.spinner').removeClass('d-none');
            $('.spinner').addClass('d-flex justify-content-center');
            this.$listItems.addClass('d-none');
            $('.paginate').addClass('d-none');
        }
    },
    unsetLoadingMode() {
        if(this.loading) {
            this.loading = false;

            $('.spinner').removeClass('d-flex justify-content-center');
            $('.spinner').addClass('d-none');
            this.$listItems.removeClass('d-none');
            $('.paginate').removeClass('d-none');

            //functions.scrollToElement(this.$listItems, 100);
        }
    },
    initComponents() {

        // register paginate        
        $('[data-page]').on('click', e => {
            e.preventDefault();
            this.paginate(+$(e.currentTarget).attr('data-page'));
        });

        contactRequest.contactRequest.init();

    },
    paginate(page) {
        if (page != this.params._page) {
            this.params._page = page;
        }

        functions.setUrlData(this.params, true, false, this.defaults);
    },
};