import Slider from "./components/Slider";
import "./components/datepicker.js";
import {DeparturePicker} from "./components/departure-picker.js";
import inputPopover from "./components/input-popover.js";
import {FormGuide, FormGuideDefaults} from "./components/form-guide";
import {InputGuests} from "./components/input-guests";
import functions from "./functions";

$(function () {

  if(! functions.getCookie('specialModal')) {
    setTimeout(() => {
      $('#specialModal').modal('show');
      functions.setCookie('specialModal', true, 1);
    }, 4000);
  }
  
  $('#specialModal').on('shown.bs.modal', e => {
    $('.modal-backdrop.show').css('opacity', '80%');
  });
  $('#specialModal').on('hidden.bs.modal', e => {
    $('.modal-backdrop.show').css('opacity', '50%');
  });
  
  $('.form-guide').each((i, elem) => {
    elem._formGuide = new FormGuide(elem);
  });

  $('.datepicker.departure-picker [data-input]').each((i, elem) => {
    elem._departurePicker = new DeparturePicker(elem);
  });

  $('.input-guests').each((i, elem) => {
    elem._inputGuests = new InputGuests(elem);
  });

  inputPopover.apply();

  $('.search-form label').on('click', e => {
    const $target = $(e.currentTarget);
    const $elem = $target.find('.select2-search__field');
    $elem[0] && $elem.focus();
  });
  $('.availability-form label').on('click', e => {
    const $target = $(e.currentTarget);
    const $select2 = $target.find('.select2');
    const $elem = $target.find('.form-control');
    $select2.length && $elem.length && $elem.focus() && $elem.select2('open');
  });

  $(document).on('click', '[data-scroll-to]', e => {
    e.preventDefault();
    const $this = $(e.target);
    let scrollTo = $this.attr('data-scroll-to').split(',');
    functions.scrollToElement(scrollTo[0], scrollTo[1] || 0);
  });

  $('#collapseItinerary').on('show.bs.collapse', e => {
      $('.show-itinerary').addClass('d-none');
  });
  $('#collapseItinerary').on('hide.bs.collapse', e => {
      $('.show-itinerary').removeClass('d-none');
  });

  if($('#hero-slider')[0]) {
    new Slider($('#hero-slider'), {
      rubberband: false,
      loop: true,
      arrows: true,
      pager: true,
      autoplay: 5000,
      slides: {
        perView: 1,
        spacing: 0,
      },
      defaultAnimation: {
        duration: 1200,
      },
    });
  }

  let pagesSlider = [];

  if($('[id^=pages-slider]')[0]) {
    $('[id^=pages-slider]').each((i, elem) => {
      pagesSlider[i] = new Slider('#' + $(elem).attr('id'), {
        rubberband: false,
        loop: true,
        arrows: true,
        pager: false,
        slides: {
          perView: 4,
          spacing: 20,
        },
        defaultAnimation: {
          duration: 1200,
        },
        breakpoints: {
          '(max-width: 768px)': {
            slides: {
              perView: 1.2,
              spacing: 20,
              //spacing: 0,
            },
          },
        }
      });
    });
  }

  let objectsSlider = [];

  if($('[id^=objects-slider]')[0]) {
    $('[id^=objects-slider]').each((i, elem) => {
      objectsSlider[i] = new Slider('#' + $(elem).attr('id'), {
        rubberband: false,
        loop: false,
        arrows: true,
        pager: false,
        slides: {
          perView: 3,
          spacing: 0,
        },
        defaultAnimation: {
          duration: 1200,
        },
        breakpoints: {
          '(max-width: 768px)': {
            slides: {
              perView: 1.2,
              //spacing: 0,
            },
          },
        }
      });
    });
  }

  if(functions.getCookie('cookie_usage') !== 'accepted') {
    $("#cookie-bar").removeClass('d-none');
  }

  $('#cookie-bar-accept').on('click', function (e) {
    functions.setCookie('cookie_usage', 'accepted');
    $("#cookie-bar").addClass('d-none');
  });

});