if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/hero-slide', {
        // built-in attributes
        title: 'Hero Slide',
        description: 'Custom Hero Slide',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            backgroundImage: {
                type: 'string',
                default: ''
            }, 
            title: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: 'POGLEDAJ PONUDU'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                link,
                backgroundImage,
                title,
                description,
                viewMore
            } = attributes;

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({backgroundImage: image.sizes.full.url});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            function onViewMoreChange(viewMore) {
                setAttributes({viewMore: viewMore});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Link' }>
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                    </PanelBody>
                    <PanelBody title={ 'Background Image Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ backgroundImage } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                    </PanelBody>
                    <PanelBody title={ 'Slide Content' }>
                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>
                        <p><strong>Description</strong></p>
                        <TextControl value={ description } onChange={ onDescriptionChange }></TextControl>
                        <p><strong>Button</strong></p>
                        <TextControl value={ viewMore } onChange={ onViewMoreChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <div class="keen-slider__slide">
                    <div class="cover-image bg-dark text-white shadow position-relative" style={{ height: 350, backgroundImage: `url(${backgroundImage})` }} >
                        <div class="container py-xl-5 position-relative">
                            <div class="text-white py-5 d-flex flex-column align-items-center">
                                <RichText 
                                    key="editable" 
                                    tagName="h1" 
                                    class="title mb-4 text-white"
                                    placeholder="Title" 
                                    value={ title }
                                    onChange={ onTitleChange }
                                    style={ { color: '#fff' } } />
                                <RichText 
                                    key="editable" 
                                    tagName="p" 
                                    class="description text-white"
                                    placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec ornare sapien eu est sociis phasellus hendrerit quam semper." 
                                    value={ description }
                                    onChange={ onDescriptionChange }
                                    style={ { color: '#fff' } } />
                                <RichText 
                                    key="editable" 
                                    tagName="a" 
                                    class="btn btn-secondary"
                                    placeholder="POGLEDAJ PONUDU" 
                                    value={ viewMore }
                                    onChange={ onViewMoreChange } 
                                    style={ { color: '#fff' } }
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                backgroundImage,
                title,
                description,
                viewMore
            } = attributes;

            return (
                <div class="keen-slider__slide">
                    <div class="lozad cover-image bg-dark text-white shadow" 
                        data-background-image={ backgroundImage }>
                        <div class="container py-xl-5 position-relative">
                            <div class="text-white py-xl-5 py-3 d-flex flex-column align-items-center">
                                <h1 class="title mb-4 text-white">{ title }</h1>
                                <RichText.Content 
                                    tagName="p" 
                                    class="description text-white"
                                    value={ description } />
                                <a href={ link } class="btn btn-secondary">{ viewMore }</a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        },
    });
}