import functions from '../functions';
import { list } from './list';
import Slider from "../components/Slider";
import pagination from '../components/pagination';
import listResults from '../components/listResults';

$(() => {
    listPackage.init();
});

const listPackage = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    defaults: {
        _page: 1,
        perPage: 5,
        language: functions.getLang(),
        pool: '',
        parking: '',
        pets: '',
        internet: '',
        dates: null,
        dateFrom: null,
        dateTo: null,
        //destination: '',
        //countryId: '',
        objectGroupId: 1,
        objectTypeId: '',
        guests: '',
        dynamicPackage: null,
        hasArrival: null,
        arrivalDateFrom: null,
        arrivalDateTo: null,
        tagCategoryId: null,
        sortBy: null,
        objectName: '',
        unitServiceIds: '',
        serviceId: '',
        hasDiscount: null,
        categoryObject: null,
        distanceBeach: null, 
        distanceCenter: null,
    },
    params: {},
    isHome: false,
    $listItems: null,
    filterParams: {
        tagCategoryIds: null,
        categoryObject: null, 
        sortBy: null, 
        objectName: null, 
        distanceBeach: null, 
        distanceCenter: null, 
        pool: null, 
        parking: null, 
        pets: null, 
        internet: null,
        objectTypeId: null,
        unitServiceIds: null,
        serviceId: null,
        hasDiscount: null,
        //categoryObjectExact: 1,
    },
    mainFields: ['dateFrom', 'dateTo', 'destination', 'objectTypeId', 'persons', 'personsInObject', 'sortBy', '_page'],
    init() {
        this.$form = $('.search-form');
        this.form = this.$form[0];

        this.$filter = $('.search-filter');
        this.filter = this.$filter[0];  

        this.$listItems = $('.list-items');
        this.isHome = this.$form.last().attr('data-ishome') || ! this.$listItems.length;

        if(! this.form || this.defaults.objectGroupId != this.$form.attr('data-objectgroupid')) {
            return;
        }

        this.defaults.dynamicPackage = this.$form.attr('data-dynamicpackage');
        //this.defaults.hasArrival = this.defaults.dynamicPackage ? 3 : null;
        this.defaults.countryId = this.$form.attr('data-countryid');
        this.defaults.destination = this.$form.attr('data-destination') ? this.$form.attr('data-destination') : this.defaults.countryId;
        this.defaults.tagCategoryId = this.$form.attr('data-tagcategoryid');
        this.filterParams.tagCategoryIds = this.$form.attr('data-tagcategoryids');
        this.filterParams.hasDiscount = this.$form.attr('data-hasdiscount');

        if(! this.isHome) {

            this.getParams();     
            functions.setFormData(this.form, this.params);
            if(this.filter) {
                functions.setFormData(this.filter, this.params);
            }

            if($('[name=guests]').val()) {
                this.params.guests = $('[name=guests]').val();
            }
            this.find(this.params);
        } else {
            functions.setFormData(this.form, this.defaults);
        }

        /*if(! this.isHome) {
            this.$form.on('change', e => {
                const $target = $(e.target);
                e.preventDefault();
    
                this.getParams(); 
                this.params = $.extend(this.params, functions.getFormData($target));
    
                functions.setUrlData(this.params, true, true);
    
            });
        }*/

        this.$form.on('submit', e => {
            const $target = $(e.target);
            e.preventDefault();

            this.isHome = $target.attr('data-ishome') || this.isHome;

            if(this.isHome) {
                this.defaults.dynamicPackage = $target.attr('data-dynamicpackage');
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                this.params.countryId = this.params.destination = $target.attr('data-countryid');
                this.defaults.countryId = this.defaults.destination = null;
                this.params.perPage = $target.attr('data-perpage') || this.defaults.perPage;
                this.params = $.extend({}, this.defaults, functions.getFormData($target));
            } else {
                this.getParams();
                this.params = $.extend(this.params, functions.getFormData($target));
                if(! this.params.dates && ! this.defaults.dynamicPackage) {
                    this.params.dateFrom = this.params.dateTo = null;
                }
            }
            this.params._page = this.defaults._page;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                this.params.tagCategoryId = $target.attr('data-tagcategoryid');
                this.params.tagCategoryIds = $target.attr('data-tagcategoryids');
                this.params.hasDiscount = $target.attr('data-hasdiscount');

                this.params.tagCategoryIds = this.params.tagCategoryIds ? this.params.tagCategoryIds : this.params.tagCategoryId;
                
                this.params = functions.cleanParams(this.params, this.defaults);
                location = $target.attr('data-listurl') + '#' + $.param(this.params);
            } else {
                functions.setUrlData(this.params, true, true, this.defaults);
            }

        });

        $('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();

            this.params.destination = '';

            const $form = $(e.currentTarget).parents('.search-form');
            const form = $form[0];

            $(form.destination).val('').trigger('change');
        });

        if(functions.isMobile()) {
            this.$filter.on('submit', e => {
                const $target = $(e.currentTarget);
                e.preventDefault();
    
                if ( this.stopChangeEvents ) {
                    return;
                }
                
                this.params = $.extend(this.params, this.filterParams, functions.getFormData($target));
                this.params._page = this.defaults._page;
                functions.setUrlData(this.params, true, false, this.defaults);
            });
        } else {
            this.$filter.on('change', e => {
                const $target = $(e.currentTarget);
                e.preventDefault();
    
                if ( this.stopChangeEvents ) {
                    return;
                }
                
                this.params = $.extend(this.params, this.filterParams, functions.getFormData($target));
                this.params._page = this.defaults._page;
                functions.setUrlData(this.params, true, false, this.defaults);
            });

            this.$filter.on('submit', e => {
                e.preventDefault();
            });
        }

        $('.btn-clear-filters').on('click', e => {
            e.preventDefault();

            this.getParams();
            this.params = $.extend(this.params, this.defaults);
            this.params._page = this.defaults._page;

            functions.setUrlData(this.params, true, false, this.defaults);
            functions.setFormData(this.filter, this.defaults);
            functions.setFormData(this.form, this.defaults);
        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.stopChangeEvents = true;

            functions.scrollToElement(this.$listItems, 100);
            this.find(this.params);
            this.stopChangeEvents = false;
        });

    },
    getParams() {
        this.params = list.getParams(this.params, this.defaults, this.filterParams);
        if(this.params.dates) {
            this.splitCalendarDates(this.params.dates);
        } else if(! this.defaults.dynamicPackage) {
            this.params.dateFrom = this.params.dateTo = null;
        }

        this.params.guests = this.params.guests.startsWith('0') ? null : this.params.guests;

        this.params.arrivalDateFrom = this.defaults.dynamicPackage && this.params.dateFrom ? this.params.dateFrom : null;
        this.params.arrivalDateTo = this.defaults.dynamicPackage && this.params.dateTo ? this.params.dateTo : null;
        this.params.hasArrival = this.defaults.dynamicPackage && this.params.arrivalDateFrom ? 3 : null;
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    find(params) {
        if(! this.params.dates) {
            this.params.dateTo = $('input[name="dateTo"]').val();
        }
        list.find(params).then((response) => {
            this.findDone(response);
            // LOZAD
            window.observer.observe();

            let listSlider = [];

            $('[id^=card-slider]').each((index, element) => {
                listSlider[index] = new Slider('#' + $(element).attr('id'), {
                    rubberband: false,
                    loop: true,
                    arrows: true,
                    slides: {
                        perView: 1,
                        spacing: 0,
                    },
                });
            });

            $('.departure').on('click', e => {
                e.preventDefault();

                const $target = $(e.currentTarget);
                let href = $target.attr('data-href');
                href = href.split('#')[0];

                const dateFrom = $target.attr('data-datefrom');
                const dateTo = $target.attr('data-dateto');

                href += '#dateFrom=' + dateFrom + '&dateTo=' + dateTo;
                //location = href; 
                window.open(href, '_blank');
            });

        });
    },
    findDone(response) {
        response = JSON.parse(response);          

        list.unsetLoadingMode();

        this.total = response.total;

        this.$listItems.html(response.html);
        if (this.total != 0) {

            if($('.paginate')[0]) {
                $('.paginate').html(pagination({
                    currentPage: this.params._page,
                    total: this.total,
                    perPage: this.params.perPage,
                }));
            }
        }

        listResults.getResults(this.total, this.params._page, this.params.perPage);

        this.initComponents();

    },
    paginate(page) {
        if (page != this.params._page) {
            this.params._page = page;
        }

        functions.setUrlData(this.params, true, false, this.defaults);
    },
    initComponents() {
        // register paginate        
        $('[data-page]').on('click', e => {
            e.preventDefault();
            this.paginate(+$(e.currentTarget).attr('data-page'));
        });

        this.$sortBy = $('[name="sortBy"]');
        this.sortBy = this.$sortBy[0] ? this.$sortBy[0] : null;

        if(this.sortBy && this.params.sortBy) {
            this.$sortBy.each((index, elem) => {
                if(elem.type === 'checkbox') {
                    elem.checked = elem.value === this.params.sortBy;
                } else if(elem.type === 'select-one') {
                    $(elem).val(this.params.sortBy);
                }
            });
        }

        this.$sortBy.on('change', e => {
            let data = functions.getFormData($(e.currentTarget));
            data.sortBy = data.sortBy ? data.sortBy : '11:DESC';
            this.params = $.extend(this.params, this.getParams(), data);
            functions.setUrlData(this.params, true, true, this.defaults);
        });
    }
};