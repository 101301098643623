if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl,
        TextareaControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/card-banner', {
        // built-in attributes
        title: 'Card Banner',
        description: 'Custom Card Banner',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            image: {
                type: 'string',
                default: ''
            }, 
            alt: {
                type: 'string',
                default: ''
            }, 
            tag: {
                type: 'string',
                default: ''
            },
            title: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: 'Pogledajte ponudu'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                link,
                image,
                alt,
                tag,
                title,
                description,
                viewMore
            } = attributes;

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({image: image.sizes.full.url});
            }

            function onAltChange(alt) {
                setAttributes({alt: alt});
            }

            function onTagChange(tag) {
                setAttributes({tag: tag});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            function onViewMoreChange(viewMore) {
                setAttributes({viewMore: viewMore});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ image } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                        <br />
                        <p><strong>Alt image:</strong></p>
                        <TextControl value={ alt } onChange={ onAltChange }></TextControl>
                        <br />
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                        <br />
                        <p><strong>Oznaka</strong></p>
                        <TextControl value={ tag } onChange={ onTagChange }></TextControl>
                        <br />
                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>
                        <br />
                        <p><strong>Description</strong></p>
                        <TextareaControl value={ description } onChange={ onDescriptionChange }></TextareaControl>
                        <br />
                        <p><strong>Link</strong></p>
                        <TextControl value={ viewMore } onChange={ onViewMoreChange }></TextControl>

                    </PanelBody>
                </InspectorControls>
                ,
                <a class="card card-banner">
                    <div class="card-img-top">
                        <img class="cover-image h-100 w-100" src={ image } alt={ alt } />
                        <div class="tag">
                            <RichText 
                                key="editable" 
                                tagName="span"
                                placeholder="RANI BOOKING" 
                                value={ tag }
                                onChange={ onTagChange } />
                        </div>
                        <div class="card-body">
                            <RichText 
                                key="editable" 
                                tagName="h4"
                                class="title"
                                placeholder="TITLE" 
                                value={ title }
                                onChange={ onTitleChange } />
                            <RichText 
                                key="editable" 
                                tagName="p"
                                class="summary-small"
                                placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." 
                                value={ description }
                                onChange={ onDescriptionChange } />
                            <button class="w-100 btn btn-sm p-0 d-flex justify-content-between align-items-center">
                                <RichText 
                                    key="editable" 
                                    tagName="span"
                                    placeholder="Pogledajte ponudu" 
                                    value={ viewMore }
                                    onChange={ onViewMoreChange } />
                                <i class="las la-arrow-right la-lg"></i>
                            </button>
                        </div>
                    </div>
                </a>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                image,
                alt,
                tag,
                title,
                description,
                viewMore
            } = attributes;

            return (
                <a class="card card-banner" href={ link }>
                    <div class="card-img-top">
                        <img class="lozad cover-image h-100 w-100" data-src={ image } alt={ alt } />
                        <div class={ tag ? "tag" : "d-none" }>
                            { tag }
                        </div>
                        <div class="card-body">
                            <h4 class="title">
                                { title }
                            </h4>
                            <RichText.Content 
                                tagName="p"
                                class="summary-small"
                                value={ description } />
                            <button class="w-100 btn btn-sm p-0 d-flex justify-content-between align-items-center">{ viewMore }<i class="las la-arrow-right la-lg"></i></button>
                        </div>
                    </div>
                </a>
            );
        },
    });
}