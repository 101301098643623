import functions from '../functions.js';
import { details } from './details';

$(() => {
    detailsAccommodation.init();
})

const detailsAccommodation = {
    $details: null,
    $form: null,
    form: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
    },
    defaults: {
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: 1,
        optionals: 'base',
    },
    init() {

        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');
        
        if(! this.$details[0] || this.params.objectGroupId != 1) {
            return;
        }
        if(+this.$details.attr('data-dynamicpackage') != 0) {
            return;
        }

        this.$form = $('.availability-form');
        this.form = this.$form[0];

        this.params.objectId = this.$details.attr('data-objectid');
        this.params.calcErrorMessages = $('[data-calcErrorMessages]')[0] ? JSON.parse($('[data-calcErrorMessages]').attr('data-calcErrorMessages')) : null;

        const $serviceCode = $('[name="serviceCode"]');
        if($serviceCode.length) {
            this.params.serviceCode = $serviceCode.val();
        }

        this.getParams();
        if(! this.params.dates && this.params.dateFrom && this.params.dateTo) {
            this.params.dates = this.params.dateFrom + ' - ' + this.params.dateTo;
        }
        
        if(this.form) {
            functions.setFormData(this.form, this.params);
        }

        if(this.params.guests) {
            $('[name=guests]').each((index, elem) => {
                $(elem).val(this.params.guests);

                let guests = this.params.guests.split(',');
                let i = 1;
                for (let index = 2; index < guests.length; index++) {
                    if(guests[index]) {
                        $('[name=child-age-'  + i + ']').val(guests[index]);
                    }
                    i++;
                }
            });
        }

        this.getCalculation();

        this.$form.on('submit', e => {
            e.preventDefault();
            let params = functions.getFormData($(e.currentTarget));
            this.params.rateId = null;
            this.params.unitId = null;
            functions.setUrlData(params, true, true);
        });

        $('.arrangement-form').on('change', e => {
            const $target = $(e.currentTarget);
            let params = functions.getFormData($target);
            params.unitId = this.params.unitId = $target.attr('data-unitid');
            params.rateId = this.params.rateId = $target.attr('data-rateid');
            params.objectGroupId = 1;
            params.pricelistId = $target.find('[name="pricelistId"]').val()

            params.guests = params.guests && params.guests.startsWith('0') ? null : params.guests;
            if(params.dates) {
                if(params.dates || params.dates == '') {
                    const aDates = params.dates.split(' - ');
                    params.dateFrom = aDates[0] ? aDates[0] : null;
                    params.dateTo = aDates[1] ? aDates[1] : null;
                }
            }

            this.getCalculation(params);
        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.getCalculation(this.params);
        });

    },
    printDates() {
        $('.unit-departure').addClass('fade');
        setTimeout(() => {
            $('.unit-departure').removeClass('fade');
            $('.date-from').text(functions.formatDate(this.params.dateFrom));
            $('.date-to').text(functions.formatDate(this.params.dateTo));
            $('.overnights').text($('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']').attr('data-duration'));
        }, 800);

    },
    getParams() {
        this.params = details.getParams(this.params, this.defaults);
        this.params.guests = this.params.guests && this.params.guests.startsWith('0') ? null : this.params.guests;
        if(this.params.dates) {
            this.splitCalendarDates(this.params.dates);
        }
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            this.params.dateFrom = aDates[0] ? aDates[0] : null;
            this.params.dateTo = aDates[1] ? aDates[1] : null;
        }
    },
    getCalculation(params) {
        const cParams = params ? params : this.params;
        const calculation = details.getCalculation(cParams);
        if(calculation) {
            this.loader(cParams.unitId ? $('.unit[data-unitid=' + cParams.unitId + ']').find('.unit-spinner') : null);
            calculation
                .done(response => {
                    this.calculationDone(response, cParams);
                }).fail(error => {
                    console.log(error);
                });
        } else if( ! cParams.rateId) {
            $('.unit-price-payment-key').toggleClass('d-none', false);

            $('.btn-booking').not('.btn-arrangement').toggleClass('d-none', true);

            $('.no-period-label').toggleClass('d-none', false);
            $('.availablility-label').toggleClass('d-none', true);
            $('.unit-price').toggleClass('d-none', false);

            $('.unit-error-container').toggleClass('d-none', true);
            $('.unit-price-total-label').toggleClass('d-none', true);

            const optionals = cParams.optionals ? cParams.optionals : this.defaults.optionals;
            let serviceText = '';
            if(optionals && optionals != this.defaults.optionals) {
                const allowedServices = JSON.parse($('[data-allowedservices]').attr('data-allowedservices'));
                allowedServices.forEach(item => {
                    if(item.supplementKey == optionals) {
                        serviceText = item.text;
                    }
                });
            }

            $('.unit').each((index, elem) => {
                const $elem = $(elem);
                const prices = $elem.attr('data-prices') ? JSON.parse($elem.attr('data-prices')) : null;
                const pricelistId = cParams.pricelistId ? cParams.pricelistId : $elem.attr('data-pricelistid');
                const item = prices ? (prices[pricelistId][optionals] ? prices[pricelistId][optionals] : prices[pricelistId]) : null;
                const pricelistsService = $elem.attr('data-pricelistsservice') ? JSON.parse($elem.attr('data-pricelistsservice')) : null;

                if(item) {
                    const $unitPriceTotal = $elem.find('.unit-price-total');
                    const $unitPriceOld = $elem.find('.unit-price-old');
                    const $unitPricePaymentKey = $elem.find('.unit-price-payment-key');
                    const $serviceSupplement = $elem.find('.service-supplement');
    
                    $unitPriceTotal.text(item.price);
                    $unitPriceOld.text(item.priceOld != '€ 0' ? item.priceOld : '');
                    $unitPricePaymentKey.text(item.paymentKey);
                    $serviceSupplement.text(pricelistsService[pricelistId] ? pricelistsService[pricelistId] : serviceText);
                }
                
                const order = $elem.attr('data-order');
                $elem.prop('style', 'order: ' + (order ? order : '10000'));

                $elem.find('.no-calculation').removeClass('d-none');
            })
        }

        if(this.params.pricelistId) {
            const pricelistsService = JSON.parse($('[data-pricelistsservice]').attr('data-pricelistsservice'));
            $('.service-supplement').text(pricelistsService[this.params.pricelistId] ? pricelistsService[this.params.pricelistId] : '')
        }

        if(this.params.serviceCode) {
            const allowedServices = JSON.parse($('[data-allowedservices]').attr('data-allowedservices'));
            let serviceText = '';
            allowedServices.forEach(item => {
                if(item.key == this.params.serviceCode) {
                    serviceText = item.text;
                }
            });
            $('.service-supplement').text(serviceText);
        }
    },
    calculationDone(response, params) {
        if(response.status) {
            const data = response.data || [];

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = this.params.rateId ? $('.arrangement-form[data-unitid=' + unitId + '][data-rateid=' + this.params.rateId + ']') : $('.unit[data-unitid=' + unitId + ']');

                if(details.isBookable(unitData)) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);
                    let discountTitle = details.getCalcDiscountTitle(calcItems);

                    if(discountTitle) {
                        $unit.find('.unit-price-discount-title').text(discountTitle);
                        $unit.find('.fm-discount-title').text(discountTitle ? discountTitle : '');
                    }

                    if(priceOld > total) {
                        $unit.find('.unit-price-old').text(currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ','));
                    }

                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));
                    
                    const $btnBooking = this.params.rateId ? $unit.find('.btn-booking[data-rateId=' + this.params.rateId + ']') : $unit.find('.btn-booking[data-unitId=' + unitId + ']');

                    $btnBooking.text(unitData.booking.status == 'Request' ? $btnBooking.attr('data-requestLabel') : $btnBooking.attr('data-bookingLabel'));

                    if($btnBooking.attr('data-href')) {
                        $btnBooking.attr('data-href',
                            $btnBooking.attr('data-href').split('?')[0] +
                            '?objectGroupId=' + params.objectGroupId +
                            '&unitId=' + unitId +
                            '&dateFrom=' + params.dateFrom +
                            '&dateTo=' + params.dateTo +
                            '&guests=' + params.guests +
                            (params.pricelistId ? '&pricelistId=' + params.pricelistId : '') +
                            (params.optionals ? '&optionals=' + params.optionals : '') +
                            (params.serviceCode ? '&serviceCode=' + params.serviceCode : '') +
                            '&addAllOptionals=' + 1
                        );
                    }

                    $btnBooking.on('click', e => {
                        e.preventDefault();
                        location = $(e.currentTarget).attr('data-href');
                    });

                    $unit.prop('style', 'order: ' + functions.formatMoney(total, 0, '', ''));

                    if(params.optionals) {
                        const allowedServices = JSON.parse($('[data-allowedservices]').attr('data-allowedservices'));
                        let serviceText = '';
                        allowedServices.forEach(item => {
                            if(item.supplementKey == params.optionals) {
                                serviceText = item.text;
                            }
                        });
                        $unit.find('.service-supplement').text(serviceText);
                    }

                    if(params.serviceCode) {
                        const allowedServices = JSON.parse($('[data-allowedservices]').attr('data-allowedservices'));
                        let serviceText = '';
                        allowedServices.forEach(item => {
                            if(item.key == params.serviceCode) {
                                serviceText = item.text;
                            }
                        });
                        $('.service-supplement').text(serviceText);
                    }

                    if(params.pricelistId) {
                        const pricelistsService = JSON.parse($('[data-pricelistsservice]').attr('data-pricelistsservice'));
                        $('.service-supplement').text(pricelistsService[params.pricelistId] ? pricelistsService[params.pricelistId] : '')
                    }
                    
                } else {
                    $unit.prop('style', 'order: 10000');
                }

                $unit.find('.unit-price-label').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-price').toggleClass('d-none', ! details.isBookable(unitData));

                $unit.find('.unit-price-discount-title').toggleClass('d-none', ! details.isBookable(unitData));
                $unit.find('.unit-price-payment-key').toggleClass('d-none', details.isBookable(unitData));

                $unit.find('.fm-discount').toggleClass('d-none', details.isBookable(unitData));
                
                $unit.find('.fm-discount-valid-until').toggleClass('d-none', details.isBookable(unitData));

                $unit.find('.no-period-label').toggleClass('d-none', details.isBookable(unitData));

                $unit.find('.btn-booking').toggleClass('d-none', ! details.isBookable(unitData));

                let error = details.getCalcErrorMessage(this.params.calcErrorMessages, unitData);
                $unit.find('.unit-error-container').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-error-container').text(error);
                
                $unit.find('.availablility-label').toggleClass('d-none', unitData.message != 'notAvailable');
                $unit.find('.no-period-label').toggleClass('d-none', unitData.message == 'notAvailable' || details.isBookable(unitData));

                $unit.find('.no-calculation').addClass('d-none');
            });
        }
    },
    loader($target = null) {
        $target ? $target.addClass('active') : $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $target ? $target.removeClass('active') : $('.unit-spinner').removeClass('active');
        }, 800);
    }
};
