import functions from '../functions';
import { list } from './list';
import pagination from '../components/pagination';
import listResults from '../components/listResults';

$(() => {
    listTravel.init();
});

const listTravel = {
    $form: null,
    form: null,
    $filter: null,
    filter: null,
    defaults: {
        _page: 1,
        perPage: 12,
        language: functions.getLang(),
        arrivalDateFrom: '',
        destination: '',
        countryId: '',
        objectGroupId: 6,
        guests: '',
        hasArrival: 4,
        hasDiscount: null,
    },
    params: {},
    isHome: false,
    $listItems: null,
    filterParams: {
        tagCategoryId: null,
        sortBy: null, 
        objectName: null,
        hasDiscount: null,
    },
    mainFields: ['arrivalDateFrom', 'destination', 'sortBy', '_page'],
    init() {
        this.$form = $('.search-form.search-travel');
        this.form = this.$form[0];

        if(! this.form || this.defaults.objectGroupId != this.$form.attr('data-objectgroupid')) {
            return;
        }

        this.$listItems = $('.list-items');
        this.isHome = this.$form.last().attr('data-ishome') || ! this.$listItems.length;

        this.listURL = this.$form.last().attr('data-listurl');
        this.defaults.objectGroupId = this.$form.last().attr('data-objectgroupid');
        this.defaults.countryId = this.defaults.destination = this.$form.last().attr('data-countryid');
        this.filterParams.tagCategoryId = this.$form.last().attr('data-tagcategoryid');
        this.filterParams.tagCategoryIds = this.$form.last().attr('data-tagcategoryids');
        this.filterParams.hasDiscount = this.$form.attr('data-hasdiscount');
        this.filterParams.sortBy = this.$form.attr('data-sortby');
        
        this.$form.on('submit', e => {
            const $target = $(e.target);
            e.preventDefault();

            this.isHome = $target.attr('data-ishome') || this.isHome;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                this.params.countryId = this.params.destination = $target.attr('data-countryid');
                this.defaults.countryId = this.defaults.destination = null;
                this.params.perPage = $target.attr('data-perpage') || this.defaults.perPage;
            }

            this.params = $.extend({}, this.defaults, functions.getFormData($target));
            this.params._page = this.defaults._page;

            if(this.isHome) {
                this.params.objectGroupId = $target.attr('data-objectgroupid');
                this.params.tagCategoryId = $target.attr('data-tagcategoryid');
                this.params.tagCategoryIds = $target.attr('data-tagcategoryids');
                this.params.hasDiscount = $target.attr('data-hasdiscount');
                
                this.params = functions.cleanParams(this.params, this.defaults);
                location = $target.attr('data-listurl') + '#' + $.param(this.params);
            } else {
                functions.setUrlData(this.params, true, true, this.defaults);
            }

        });

        if(! this.isHome) {

            this.getParams();           
            functions.setFormData(this.form, this.params);
            this.params.guests = $('[name=guests]').val();
            this.find(this.params);
        } else {
            functions.setFormData(this.form, this.defaults);
        }

        $('.ms-clear').on('click', e => {
            e.preventDefault();
            e.stopPropagation();
            this.params.destination = '';
            $(this.form.destination).val('').trigger('change');
        });

        $('[name="tagCategoryId"]').on('change', e => {
            this.params._page = this.defaults._page;
            this.getParams();
            this.setTagCategoryId();
            //this.find(this.params);
            functions.setUrlData(this.params, true, true, this.defaults);
        });
        $('[name="hasDiscount"]').on('change', e => {
            this.params._page = this.defaults._page;
            this.getParams();
            this.params.hasDiscount = e.currentTarget.checked ? $(e.currentTarget).val() : null;
            functions.setUrlData(this.params, true, true, this.defaults);
        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.setTagCategoryId();
            this.stopChangeEvents = true;

            functions.scrollToElement(this.$listItems, 100);
            this.find(this.params);
            this.stopChangeEvents = false;
        });
    },
    getParams() {      
        this.params = list.getParams(this.params, this.defaults, this.filterParams);

        if(this.params.arrivalDateFrom) {
            
            let dateFrom = new Date(this.params.arrivalDateFrom);
            let month = dateFrom.getMonth() + 1;
            
            let lastDateOfMonth = new Date(dateFrom.getFullYear(), month, 0);
            this.params.arrivalDateTo = lastDateOfMonth.getFullYear() + '-' + (lastDateOfMonth.getMonth() + 1) + '-' + lastDateOfMonth.getDate();
        }
    },
    setTagCategoryId() {
        let tagCategoryIds = [];
        $('[name="tagCategoryId"]').each((index, target) => {
            const $target = $(target);
            if($target[0].checked) {
                tagCategoryIds.push($target.val());
            }
        });
        this.params.tagCategoryId = tagCategoryIds.join(",");
    },
    find(params) {
        list.find(params).then((response) => {
            this.findDone(response);
            // LOZAD
            window.observer.observe();
        });
    },
    findDone(response) {
        response = JSON.parse(response);          

        list.unsetLoadingMode();

        this.total = response.total;

        this.$listItems.html(response.html);
        if (this.total != 0) {

            if($('.paginate')[0]) {
                $('.paginate').html(pagination({
                    currentPage: this.params._page,
                    total: this.total,
                    perPage: this.params.perPage,
                }));
            }
        }

        listResults.getResults(this.total, this.params._page, this.params.perPage);

        this.initComponents();

    },
    paginate(page) {
        if (page != this.params._page) {
            this.params._page = page;
        }

        functions.setUrlData(this.params, true, false, this.defaults);
    },
    initComponents() {
        // register paginate        
        $('[data-page]').on('click', e => {
            e.preventDefault();
            this.paginate(+$(e.currentTarget).attr('data-page'));
        });

        this.$sortBy = $('[name="sortBy"]');
        this.sortBy = this.$sortBy[0] ? this.$sortBy[0] : null;

        if(this.sortBy && this.params.sortBy) {
            this.$sortBy.each((index, elem) => {
                if(elem.type === 'checkbox') {
                    elem.checked = elem.value === this.params.sortBy;
                } else if(elem.type === 'select-one') {
                    $(elem).val(this.params.sortBy);
                }
            });
        }

        this.$sortBy.on('change', e => {
            let data = functions.getFormData($(e.currentTarget));
            data.sortBy = data.sortBy ? data.sortBy : '11:DESC';
            this.params = $.extend(this.params, this.getParams(), data);
            functions.setUrlData(this.params, true, true, this.defaults);
        });
    }

};