import functions from '../functions.js';
import { details } from './details';
import Slider from '../components/Slider';
import {DeparturePicker} from "../components/departure-picker";

$(() => {
    detailsPackage.init();
})

const detailsPackage = {
    $details: null,
    $form: null,
    form: null,
    enable: null,
    flights: null,
    prices: null,
    departures: null,
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        time: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
        dynamicPackage: 1,
        allowedServices: null,
    },
    defaults: {
        guests: null, 
        dates: null,
        time: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: 1,
        dynamicPackage: 1,
    },
    init() {

        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');

        if(! this.$details[0] || this.params.objectGroupId != 1) {
            return;
        }
        if(+this.$details.attr('data-dynamicpackage') != this.params.dynamicPackage) {
            return;
        }

        this.allowedServices = JSON.parse($('[data-allowedservices]').attr('data-allowedservices'));;

        this.params.objectId = this.$details.attr('data-objectid');
        this.params.calcErrorMessages = $('[data-calcErrorMessages]')[0] ? JSON.parse($('[data-calcErrorMessages]').attr('data-calcErrorMessages')) : null;

        const $serviceCode = $('[name="serviceCode"]');
        if($serviceCode.length) {
            this.params.serviceCode = $serviceCode.val();
        }

        this.getParams();

        this.$form = $('form.availability-form');
        this.form = this.$form[0];

        $.post('/services/dynamic_package_availability_form_data/', {
            objectId: this.params.objectId,
            dynamicPackage: 1,
            language: functions.getLang(),
        }).always(response => {
            response = response ? JSON.parse(response) : null;
            if (response.status) {

                const prepared = this.getPackageDepartures(response.data.items, response.data.flights);
                const serviceCodes = response.data.serviceCodes;
                const $service = $("[name='serviceCode']");
                const $serviceWrapper = $service.parents('.service-code-wrapper');
                serviceCodes.length ? $serviceWrapper.removeClass('d-none') : $serviceWrapper.addClass('d-none');

                this.enable = prepared.enable;
                this.flights = prepared.flights;
                this.prices = prepared.prices;

                serviceCodes.forEach((serviceCode, key) => {
                    this.allowedServices.forEach((allowedService) => {
                        if (allowedService.key == serviceCode){
                            //serviceCodes[key] = allowedService;
                            serviceCodes[key] = {id: allowedService.key};
                            serviceCodes[key].text = allowedService.text;

                            if ( ! this.params.serviceCode) {
                                this.params.serviceCode = allowedService.key;
                                this.params.serviceCodeId = allowedService.id;
                            }
                        }
                    });
                });

                $("#details-datepicker").attr('data-departures', JSON.stringify(this.enable));
                $("#details-datepicker").attr('data-flights', JSON.stringify(this.flights));

                $("#details-datepicker").attr('data-prices', JSON.stringify(this.prices));

                $('.departure-picker-dynamic-package [data-input]').each((i, elem) => {
                    elem._departurePicker = new DeparturePicker(elem);
                });

                $service.select2({
                    data: serviceCodes,
                    minimumResultsForSearch: -1
                });
                setTimeout(() => $service.val(this.params.serviceCode).trigger('change'));
                //setTimeout(() => $service.val(this.params.serviceCodeId).trigger('change'));

                this.setDefaultDeparture();
                functions.setFormData(this.form, this.params);

                this.showFlightTickets();
                this.setPrices();

                functions.setFormData(this.form, this.params);

                this.getCalculation();
            }
        })


        //this.initDepartureCalendar();
        //this.setDefaultDeparture();

        //const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']');
        //this.showFlightTickets($departure);
        //this.setPrices($departure);

        /*$('#booking-calendar-slider .keen-slider__slide').removeClass('active');
        $departure.parents('.keen-slider__slide').addClass('active');

        const instance = this.slider.instance;
        const slides = this.slider.instance.slides;
        slides.forEach((slide, index) => {
            if($(slide).hasClass('active')) {
                instance.moveToIdx(index);
            }
        });*/

        /*$('.departure').on('click', e => {
            $('.departure').removeClass('active');

            const $target = $(e.currentTarget);
            
            this.params.dateFrom = $target.attr('data-datefrom');
            this.params.dateTo = $target.attr('data-dateto');
            
            $target.addClass('active');

            this.showFlightTickets($target);
            this.setPrices($target);
            this.printDates();

            functions.setUrlData({'dateFrom': this.params.dateFrom, 'dateTo': this.params.dateTo, 'guests': this.params.guests }, true, true);

        });*/

        /*$('[name=guests]').on('change', e => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            //this.params.unitId = $target.attr('data-unitid');
            this.params.guests = $target.val();

            functions.setUrlData({'dateFrom': this.params.dateFrom, 'dateTo': this.params.dateTo, 'guests': this.params.guests }, true);
            //this.getCalculation();
        });*/

        if(this.params.guests) {
            $('[name=guests]').each((index, elem) => {
                $(elem).val(this.params.guests);

                let guests = this.params.guests.split(',');
                let i = 1;
                for (let index = 2; index < guests.length; index++) {
                    if(guests[index]) {
                        $('[name=child-age-'  + i + ']').val(guests[index]);
                    }
                    i++;
                }
            });
            $('[name=guests]').trigger('change');
        }

        $('form.availability-form').on('submit', e => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            const data = functions.getFormData($target);

            this.params.dateFrom = data.dateFrom;
            this.params.dateTo = data.dateTo;
            this.params.time = data.time;
            this.params.guests = data.guests;
            this.params.serviceCode = data.serviceCode;

            functions.setUrlData({
                'dateFrom': this.params.dateFrom,
                'dateTo': this.params.dateTo,
                'time': this.params.time,
                'guests': this.params.guests,
                'serviceCode': this.params.serviceCode,
            }, true, true);
            //this.getCalculation();
        });

        $(window).on('hashchange', e => {
            this.getParams();
            //this.params.guests = $('[name=guests]').val();

            //const $target = $('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']');
            
            //this.params.dateFrom = $target.attr('data-datefrom');
            //this.params.dateTo = $target.attr('data-dateto');
            
            //$target.addClass('active');

            this.showFlightTickets();
            this.setPrices();
            //this.printDates();

            this.getCalculation();

           /* $('.unit').each((index, unit) => {
                const $unit = $(unit);
                this.params.unitId = $unit.attr('data-unitid');
                this.params.guests = $unit.find('[name=guests]').val();
                this.getCalculation();
            });*/
        });

        $('[id^=cenovnikModal]').on('show.bs.modal', e => {
           const $target = $(e.currentTarget);
           let params = JSON.parse($target.attr('data-params'))

           const $unitPricelist = $('.unit-pricelist-' + params.unitId);
           const $unitPricelistSpinner = $('.unit-pricelist-spinner-' + params.unitId);

           if(! $unitPricelist.children().length) {
               $.get('/services/get_pricelists/', params).done(response => {
                   $unitPricelist.html(response);
                   $unitPricelistSpinner.addClass('d-none');
               })
           }
        });

    },
    getPackageDepartures(rates, flightTitles) {
        let departures = {};

        Object.keys(rates).forEach(index => {
            let rate = rates[index];
            let unique = rate.dateFrom + ',' + rate.dateTo;

            let flight = rate.flight;
            let flightUnitId = flight.unitId;
            let unitId = rate['unitId']['id'];

            if ( ! departures[unique])
            {
                let flightIds = {};
                flightIds[flightUnitId] = {};
                flightIds[flightUnitId][unitId] = rate.id;

                let flights = {};
                flights[flightUnitId] = flight;

                let rates = {};
                rates[rate.id] = rate;
                if ( flight.timeDepartureFrom ) {
                    flight.time = flight.timeDepartureFrom + ',' + (flight.timeReturnFrom || '');
                }
                departures[unique] = {
                    'dateFrom'  : rate.dateFrom,
                    'dateTo'    : rate.dateTo,
                    'time'      : flight.time || '',
                    'duration'  : rate.accommodationDuration,
                    'nights'    : rate.accommodationDuration,
                    'flightIds' : flightIds,
                    'flights'   : flights,
                    'rates'     : rates
                };
            }
            else
            {
                let departure = departures[unique];

                if ( ! departure.flightIds[flightUnitId])
                {
                    let flightIds = {};
                    flightIds[flightUnitId] = {};
                    flightIds[flightUnitId][unitId] = rate.id;

                    departure.flightIds[flightUnitId] = {};
                    departure.flightIds[flightUnitId][unitId] = rate.id;
                }
                else if ( ! departure.flightIds[flightUnitId][unitId])
                {
                    departure.flightIds[flightUnitId][unitId] = rate.id;
                }

                departure.flights[flightUnitId] = flight;
                departure.rates[rate.id] = rate;
            }
        });

        this.departures = departures;
        //console.log(departures);

        let enable = [];
        let prices = {};
        let flights = [];

        Object.keys(departures).forEach(function(index) {

            let rate = departures[index];

            enable.push({
                dateFrom: rate['dateFrom'],
                dateTo: rate['dateTo'],
                duration: rate['duration'],
                nights: rate['duration'],
                time: rate['time']
            })

            prices[index] = detailsPackage.getDeparturePrices(rate);

            let arrival = rate.dateFrom;
            let departure = rate.dateTo;

            let arrivalDay = detailsPackage.getWeekDay(arrival);
            let arrivalDayDeparture = detailsPackage.getWeekDay(departure);

            Object.keys(rate.flights).forEach(function(index) {

                let flight = rate.flights[index];

                let flightUnitId = flight['unitId'];
                let rateIds = rate['flightIds'][flightUnitId];

                let timeDepartureFrom = flight['timeDepartureFrom'];
                let timeDepartureTo = flight['timeDepartureTo'];
                let timeReturnFrom = flight['timeReturnFrom'];
                let timeReturnTo = flight['timeReturnTo'];
                let placeFromName = flight['placeFrom'];
                let placeToName = flight['placeTo'];

                let flightTitle;
                flightTitles.forEach((ft) => {
                    if (ft.id == flight.objectId) {
                        flightTitle = ft.title;
                    }
                });

                flights.push({
                    dateFrom              : rate.dateFrom,
                    dateTo                : rate.dateTo,
                    flightId              : flight.objectId,
                    flightTitle           : flightTitle,
                    flightUnitId          : flight.unitId,
                    rateIds               : rateIds,
                    arrivalDay            : arrivalDay,
                    arrivalDayDeparture   : arrivalDayDeparture,
                    timeDepartureFrom     : timeDepartureFrom,
                    timeDepartureTo       : timeDepartureTo,
                    timeReturnFrom        : timeReturnFrom,
                    timeReturnTo          : timeReturnTo,
                    time                  : flight.time,
                    placeFromName         : placeFromName,
                    placeToName           : placeToName,
                });
            });
        });

        return {
            enable : enable,
            prices : prices,
            flights : flights
        };
    },
    getWeekDay(date) {

        const d = new Date(date);
        const days = [
            "nedelja",
            "ponedeljak",
            "utorak",
            "sreda",
            "četvrtak",
            "petak",
            "subota"
        ];

        let day = d.getDay()

        return days[day];
    },
    getDeparturePrices(rate) {
        let departurePrices = {};
        let flights = rate.flightIds;
        let rates = rate.rates;

        Object.keys(flights).forEach(function(flightId) {
            let items = flights[flightId];
            Object.keys(items).forEach(function(unitId) {
                let rateId = items[unitId];
                let unitRate = rates[rateId];

                if (unitRate) {

                    let validUntil = null;
                    if (unitRate.specialDiscount && unitRate.specialDiscount.validUntil) {
                        validUntil = unitRate.specialDiscount.validUntil;
                    }

                    var flightUnitId = flightId;

                    if ( ! departurePrices[unitId]) departurePrices[unitId] = {};

                    departurePrices[unitId][flightUnitId] = {
                        price : '€ ' + functions.formatMoney(unitRate.price, 0, '', ''),
                        priceWithDiscount : unitRate.priceWithDiscount ? ('€ ' + functions.formatMoney(unitRate.priceWithDiscount, 0, '', '')) : null,
                        discountTitle : unitRate.specialDiscount && unitRate.specialDiscount.itemDefinitionId.text ? unitRate.specialDiscount.itemDefinitionId.text : null,
                        validUntil : validUntil,
                        paymentKey : unitRate.paymentTypeKey == 'Once' ? 'po boravku' : 'po osobi'
                    };
                }
            });
        });

        return departurePrices;
    },
    setPrices() {

        let guests = this.params.guests && ! this.params.guests.startsWith('0');

        $('.unit-price-old').addClass('d-none');
        $('.unit-price-total').addClass('d-none');
        $('.fm-discount').addClass('d-none');
        $('.unit-price-label').addClass('d-none');
        $('.service-supplement').addClass('d-none');
        $('.unit-price-payment-key').addClass('d-none');

        $('.availablility-label').addClass('d-none');
        $('.unit-error-container').addClass('d-none');
        $('.unit-price-total-label').addClass('d-none');
        $('.no-period-label').addClass('d-none');
        $('.btn-booking').addClass('d-none');
        $('.unit').attr('style', 'order: 2');

        if(! guests) {
            let prices = JSON.parse($('[data-prices]').attr('data-prices'));

            prices = prices[this.params.dateFrom + ',' + this.params.dateTo];
            this.loader();

            setTimeout(() => {
                Object.entries(prices).forEach(item => {
                    const unitId = item[0];

                    const flightUnitId = $('.flight-ticket.active').attr('data-flightunitid');
                    const rate = item[1][flightUnitId];
        
                    const $unit = $('.unit[data-unitid="' + unitId + '"]');
                    $unit.attr('style', 'order: 1');
                    
                    $unit.find('.unit-price-old').removeClass('d-none').text(rate.priceWithDiscount ? rate.price : '');
                    $unit.find('.unit-price-total').removeClass('d-none').text(rate.priceWithDiscount ? rate.priceWithDiscount : rate.price);

                    $unit.prop('style', 'order: ' + functions.formatMoney(rate.price, 0, '', ''));
                    
                    $unit.find('.fm-discount').toggleClass('d-none', ! rate.discountTitle);

                    $unit.find('.fm-discount-title').text(rate.discountTitle ? rate.discountTitle : '');
                    $unit.find('.fm-discount-title').toggleClass('d-none', rate.validUntil != null);

                    $unit.find('.fm-discount-valid-until').toggleClass('d-none', ! rate.validUntil);
                    $unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? functions.formatDate(rate.validUntil) : '');

                    $unit.find('.service-supplement').removeClass('d-none');
                    $unit.find('.unit-price-payment-key').removeClass('d-none').text(rate.paymentKey ? rate.paymentKey : '');

                    $unit.find('.unit-price-label').removeClass('d-none');
                    $unit.find('.no-period-label').removeClass('d-none');
                });
            }, 800);
        }

    },
    showFlightTickets() {

        const dataFlights = JSON.parse($('[data-flights]').attr('data-flights'));


        let dateFrom = this.params.dateFrom;
        let dateTo = this.params.dateTo;

        let flights = dataFlights.filter(flight => {
            return flight.dateFrom == dateFrom && flight.dateTo == dateTo;
        });

        this.renderFlightTickets(flights);

        //$('.flight-ticket').addClass('d-none');
        
        //const $tickets = $('.flight-ticket[data-datefrom="' + dateFrom + '"][data-dateto="' + dateTo + '"]');
        //$tickets.removeClass('d-none');
        
        // set active ticket

        this.setFlightTicket(flights);
    },
    renderFlightTickets(flights) {
        const $flights = $('.flights').empty();
        flights.forEach((flight, index) => {
            $flights.append(`
                <div class="flight-ticket ${ index == 0 ? 'active' : '' } mb-3 pointer"
                    data-flight='${ JSON.stringify(flight) }' 
                    data-flightId="${ flight.flightId }"
                    data-flightUnitId="${ flight.flightUnitId }"
                    data-rateIds="${ flight.rateIds }"
                    data-dateFrom="${ flight.dateFrom }"
                    data-dateTo="${ flight.dateTo }">
                    <div class="row g-0">
                        <div class="col-12 bg-white rounded">
                            <div class="flight-title-wrapper px-4 pe-lg-5 py-2 bg-primary bg-opacity-25 rounded${ flight.flightTitle ? '' : ' d-none' }">
                                <h5 class="flight-title mb-0">${ flight.flightTitle }</h5>
                            </div>
                        </div>
                        <div class="col">
                            <div class="flight-ticket-body bg-white shadow rounded overflow-hidden">
                                <div class="row px-4 pe-lg-5 py-3">
                                    <div class="col-lg mb-3 mb-lg-0">
                                        <div class="d-flex justify-content-between mb-2">
                                            <div class="d-flex align-items-center">
                                                <i class="las la-plane-departure la-2x me-3"></i>
                                                <div class="text-muted fw-bold mb-0 text-uppercase me-2">Odlazak</div>
                                                <div class="d-flex align-items-end">
                                                    <div class="departure-date text-primary fw-bold mb-0" data-flight-DateFrom>${ functions.formatDate(flight.dateFrom) }</div>
                                                    <div class="departure-day small ms-2 lh-1 mb-1 text-uppercase" data-flight-DateFromDay>${ flight.arrivalDay }</div>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div class="row">
                                            <div class="col-12 col-lg-auto d-flex flex-row flex-lg-column justify-content-between">
                                                <div class="departure-place-from small fw-bold mb-1 text-muted">${ flight.placeFromName }</div>
                                                ${ flight.timeDepartureFrom ? '<div class="departure-time-from h6 h-lg-3 mb-0" data-flight-TimeDepartureFrom>' + flight.timeDepartureFrom + '</div>' : '' }
                                            </div>
                                            <div class="col-auto col-lg d-none d-lg-flex lign-items-center">
                                                <div class="w-100 bg-primary mt-2" style="height: 1px;"></div>
                                            </div>
                                            <div class="col-12 col-lg-auto text-end d-flex flex-row flex-lg-column justify-content-between">
                                                <div class="departure-place-to small fw-bold mb-1 text-muted">${ flight.placeToName }</div>
                                                ${ flight.timeDepartureTo ? '<div class="departure-time-to h6 h-lg-3 mb-0" data-flight-TimeDepartureTo>' + flight.timeDepartureTo + '</div>' : '' }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-auto">
                                        <div class="d-none d-lg-block h-100 bg-primary" style="width: 1px;"></div>
                                        <div class="d-block d-lg-none w-100 bg-primary mb-2" style="height: 1px;"></div>
                                    </div>
                                    <div class="col-lg">
                                        <div class="d-flex justify-content-between mb-2">
                                            <div class="d-flex align-items-center">
                                                <i class="las la-plane-departure la-2x me-3"></i>
                                                <div class="fw-bold text-muted mb-0 text-uppercase me-2">Povratak</div>
                                                <div class="d-flex align-items-center">
                                                    <div class="return-date fw-bold mb-0 text-primary" data-flight-DateTo>${ functions.formatDate(flight.dateTo) }</div>
                                                    <div class="return-day small ms-2 text-uppercase" data-flight-DateToDay>${ flight.arrivalDayDeparture }</div>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div class="row">
                                            <div class="col-12 col-lg-auto d-flex flex-row flex-lg-column justify-content-between">
                                                <div class="return-place-from small fw-bold mb-1 text-muted">${ flight.placeToName }</div>
                                                ${ flight.timeReturnFrom ? '<div class="return-time-from h6 h-lg-3 mb-0" data-flight-TimeReturnFrom>' + flight.timeReturnFrom + '</div>' : '' }
                                            </div>
                                            <div class="col-lg d-none d-lg-flex lign-items-center">
                                                <div class="w-100 bg-primary mt-2" style="height: 1px;"></div>
                                            </div>
                                            <div class="col-12 col-lg-auto text-end d-flex flex-row flex-lg-column justify-content-between">
                                                <div class="return-place-to small fw-bold mb-1 text-muted">${ flight.placeFromName }</div>
                                                ${ flight.timeReturnTo ? '<div class="return-time-to h6 h-lg-3 mb-0" data-flight-TimeReturnTo>' + flight.timeReturnTo + '</div>' : '' }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1">
                            <div class="ticket-tail shadow rounded d-flex justify-content-center align-items-center h-100 p-2">
                                <div class="bg-white border border-primary rounded-circle p-2">
                                    <i class="las la-check la-lg text-primary"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            `);
        });
    },
    setFlightTicket(flights) {
        /*$('.flight-ticket').removeClass('active');
        if($tickets) {
            $tickets.addClass('active')
        }*/
        this.setUnitRateIds(flights[0]);

        $('.flight-ticket').on('click', e => {
            $('.flight-ticket').removeClass('active');
            const $target = $(e.currentTarget);
            $target.addClass('active');

            const flight = JSON.parse($target.attr('data-flight'));
            this.params.time = flight.timeDepartureFrom && flight.timeReturnFrom ? flight.timeDepartureFrom + ':' + flight.timeReturnFrom : this.params.time;

            let urlData = functions.getUrlData(true);
            urlData.time = this.params.time;
            functions.setUrlData(urlData, true, true);

            this.setUnitRateIds(flight);
            this.getParams();
            this.getCalculation();
        });
    },
    setUnitRateIds(flight) {
        //console.log(flight);
        const rateIds = flight.rateIds;
        //console.log(rateIds);
        const flightId = this.params.flightId = flight.flightId;
        const flightUnitId = this.params.flightUnitId = flight.flightUnitId;
        Object.entries(rateIds).forEach(item => {
            const unitId = item[0];
            const rateId = item[1];

            $('.btn-booking[data-unitid=' + unitId + ']').attr('data-rateid', rateId);
            $('.btn-booking[data-unitid=' + unitId + ']').attr('data-flightid', flightId);
            $('.btn-booking[data-unitid=' + unitId + ']').attr('data-flightunitid', flightUnitId);
        })
    },
    initDepartureCalendar() {
        const $bookingCalendar = $('#booking-calendar-slider');

        if($bookingCalendar[0]) {
            const length = $bookingCalendar.find('.keen-slider__slide').length;
            const perView = 8;
            
            let slidesPerView = length < perView ? length : perView;

            if(length < perView) {
                $('.booking-calendar-wrapper').addClass('col-lg-' + (slidesPerView + 2));
                $('.booking-calendar-wrapper').addClass('mx-auto');
            }
    
            this.slider = new Slider($bookingCalendar, {
                // mode: 'snap',
                //centered: true,
                duration: 200,
                fade: false,
                loop: false,
                controls: false,
                initial: 0,
                arrows: functions.isMobile() || length > slidesPerView,
                slides: {
                    perView: slidesPerView,
                    spacing: 0,
                },
                breakpoints: {
                    '(min-width: 577px) and (max-width: 1024px)': {
                        controls: true,
                        arrows: length > 6,
                        //centered: true,
                        slides: {
                            perView: 6.2,
                        },
                    },
                    '(max-width: 576px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 2.2,
                        },
                    },
                },
            });
        }
    },
    setDefaultDeparture(departures) {
       
        this.params.dateFrom = ! this.params.dateFrom && this.departures ? this.departures[Object.keys(this.departures)[0]].dateFrom : this.params.dateFrom;
        this.params.dateTo = ! this.params.dateTo && this.params.dateFrom && this.departures ? this.departures[Object.keys(this.departures)[0]].dateTo : this.params.dateTo;
        let departure = this.departures ? this.departures[this.params.dateFrom + ',' + this.params.dateTo] : null;
        departure && (this.params.time = departure.time);

        /*this.params.dateFrom = ! this.params.dateFrom ? $('.departure[data-datefrom]').first().attr('data-datefrom') : this.params.dateFrom;
        this.params.dateTo = ! this.params.dateTo && this.params.dateFrom ? $('.departure[data-datefrom=' + this.params.dateFrom + ']').first().attr('data-dateto') : this.params.dateTo;
        let $first = $('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']');

        if(! $first.length) {
            this.params.dateFrom = $('.departure[data-datefrom]').first().attr('data-datefrom');
            this.params.dateTo = $('.departure[data-datefrom=' + this.params.dateFrom + ']').first().attr('data-dateto');
            $first = $('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']');
        }

        $('.departure').removeClass('active');
        $first.addClass('active');*/

        //this.params.guests = $('[name=guests]').val(); 

        //functions.setUrlData({ 'dateFrom': this.params.dateFrom, 'dateTo': this.params.dateTo, 'guests': this.params.guests }, true, true);

        //this.printDates();
    },
    printDates() {
        $('.unit-departure').addClass('fade');
        setTimeout(() => {
            $('.unit-departure').removeClass('fade');
            $('.date-from').text(functions.formatDate(this.params.dateFrom));
            $('.date-to').text(functions.formatDate(this.params.dateTo));
            $('.overnights').text($('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']').attr('data-duration'));
        }, 800);

    },
    getParams() {
        this.params = details.getParams(this.params, this.defaults);
        this.params.guests = this.params.guests && this.params.guests.startsWith('0') ? null : this.params.guests;
    },
    getCalculation() {

        $('.unit-spinner').addClass('active');

        const calculation = details.getCalculation(this.params);
        if(calculation) {
            calculation
                .done(response => {
                    $('.unit-spinner').removeClass('active');
                    this.calculationDone(response);
                }).fail(error => {

                });
        } else {

            setTimeout(() => {
                $('.unit-spinner').removeClass('active');
            }, 800);

            this.setPrices($('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']'));

            if(this.params.serviceCode) {

                let serviceText = '';
                this.allowedServices.forEach(item => {
                    if(item.key == this.params.serviceCode) {
                        serviceText = item.text;
                    }
                });
                $('.service-supplement').text(serviceText);
            }
        }
    },
    calculationDone(response) {

        if(response.status) {
            const data = response.data || [];

            //console.log(data);

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = $('.unit[data-unitid=' + unitId + ']');

                let discountTitle = null;

                if(details.isBookable(unitData)) {
                    const unitCalc = unitData.calc;
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);
                    priceOld = +priceOld.toFixed(2);
                    discountTitle = details.getCalcDiscountTitle(calcItems);

                    if(discountTitle) {
                        //console.log(discountTitle);
                        $unit.find('.unit-price-discount-title').text(discountTitle);
                        $unit.find('.fm-discount-title').text(discountTitle);
                        $unit.find('.fm-discount-title').toggleClass('d-none', false);
                    }

                    $unit.find('.unit-price-old').removeClass('d-none').text(priceOld > total ? currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ',') : '');
                    
                    //$unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').removeClass('d-none').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));
                    
                    //this.printDates();

                    const $btnBooking = $unit.find('.btn-booking[data-unitId=' + unitId + ']').removeClass('d-none');
                    //const rateId = $btnBooking.attr('data-rateid');
                    //const flightId = $btnBooking.attr('data-flightid');
                    const flightUnitId = $btnBooking.attr('data-flightunitid');

                    $btnBooking.text(unitData.booking.status == 'Request' ? $btnBooking.attr('data-requestLabel') : $btnBooking.attr('data-bookingLabel'));

                    if($btnBooking.attr('data-href')) {
                        $btnBooking.attr('data-href',
                            $btnBooking.attr('data-href').split('?')[0] +
                            '?objectGroupId=' + this.params.objectGroupId +
                            '&unitId=' + unitId +
                            '&dateFrom=' + this.params.dateFrom +
                            '&dateTo=' + this.params.dateTo +
                            '&guests=' + this.params.guests +
                            '&addAllOptionals=1' + 
                            '&dynamicPackage=1' + 
                            '&flightUnitId=' + flightUnitId +
                            (this.params.serviceCode ? '&serviceCode=' + this.params.serviceCode : '') +
                            (this.params.time ? '&time=' + this.params.time : '')
                            //'&pricelistItemId=' + rateId
                        );
                    }

                    $btnBooking.on('click', e => {
                        e.preventDefault();
                        location = $(e.currentTarget).attr('data-href');
                    });

                    $unit.prop('style', 'order: ' + functions.formatMoney(total, 0, '', ''));

                    if(this.params.serviceCode) {
                        const allowedServices = JSON.parse($('[data-allowedservices]').attr('data-allowedservices'));
                        let serviceText = '';
                        allowedServices.forEach(item => {
                            if(item.key == this.params.serviceCode) {
                                serviceText = item.text;
                            }
                        });
                        $('.service-supplement').text(serviceText);
                    }

                } else {
                    $unit.prop('style', 'order: 10000;');
                }

                $unit.find('.unit-price-total-label').toggleClass('d-none', ! details.isBookable(unitData));

                $unit.find('.unit-price-label').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-price').toggleClass('d-none', ! details.isBookable(unitData));

                $unit.find('.unit-price-discount-title').toggleClass('d-none', ! details.isBookable(unitData));
                $unit.find('.unit-price-payment-key').toggleClass('d-none', details.isBookable(unitData));

                $unit.find('.fm-discount').toggleClass('d-none', discountTitle == null || ! details.isBookable(unitData));
                
                $unit.find('.fm-discount-valid-until').toggleClass('d-none', details.isBookable(unitData));
                //$unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');

                $unit.find('.no-period-label').toggleClass('d-none', details.isBookable(unitData));

                $unit.find('.btn-booking').toggleClass('d-none', ! details.isBookable(unitData));

                //$unit.find('.btn-booking-container').toggleClass('d-none', ! details.isBookable(unitData));
                //$unit.find('.btn-request-nobookable').toggleClass('d-none', details.isBookable(unitData));

                let error = details.getCalcErrorMessage(this.params.calcErrorMessages, unitData);
                $unit.find('.unit-error-container').toggleClass('d-none', details.isBookable(unitData));
                $unit.find('.unit-error-container').text(error);

                $unit.find('.availablility-label').toggleClass('d-none', unitData.message != 'notAvailable');
                $unit.find('.no-period-label').toggleClass('d-none', unitData.message == 'notAvailable' || details.isBookable(unitData));

                $unit.find('.service-supplement').removeClass('d-none');
            });
        }
    },
    loader($target = null) {
        /*$target ? $target.addClass('active') : $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $target ? $target.removeClass('active') : $('.unit-spinner').removeClass('active');
        }, 800);*/
    }
};
