if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/special-popup', {
        // built-in attributes
        title: 'Special Popup',
        description: 'Custom Special Popup',
        icon: 'slides',
        category: 'custom-components',
        example: {},

        // custom attributes
        attributes: {
            link: {
                type: 'string',
                default: '',
            },
            backgroundImage: {
                type: 'string',
                default: ''
            }, 
            title: {
                type: 'string',
                default: ''
            },
            subtitle: {
                type: 'string',
                default: ''
            },
            description: {
                type: 'string',
                default: ''
            },
            viewMore: {
                type: 'string',
                default: 'POGLEDAJ PONUDU'
            },
        },

        // built-in functions
        edit({ attributes, setAttributes }) {

            const {
                link,
                backgroundImage,
                title,
                subtitle,
                description,
                viewMore
            } = attributes;

            // custom functions
            function onLinkChange (link) {
                setAttributes({link: link});
            };

            function onSelectImage (image) {
                setAttributes({backgroundImage: image.sizes.full.url});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onSubtitleChange(subtitle) {
                setAttributes({subtitle: subtitle});
            }

            function onDescriptionChange(description) {
                setAttributes({description: description});
            }

            function onViewMoreChange(viewMore) {
                setAttributes({viewMore: viewMore});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Link' }>
                        <p><strong>Enter url: (ex: /putovanja)</strong></p>
                        <TextControl value={ link } onChange={ onLinkChange }></TextControl>
                    </PanelBody>
                    <PanelBody title={ 'Background Image Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ backgroundImage } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                    </PanelBody>
                    <PanelBody title={ 'Popup Content' }>
                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>
                        <p><strong>Subtitle</strong></p>
                        <TextControl value={ subtitle } onChange={ onSubtitleChange }></TextControl>
                        <p><strong>Description</strong></p>
                        <TextControl value={ description } onChange={ onDescriptionChange }></TextControl>
                        <p><strong>Button</strong></p>
                        <TextControl value={ viewMore } onChange={ onViewMoreChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <a class="special-modal-card cover-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
                    <div class="logo-featured">
                        <img alt="Olympic Travel Logo" src="/wp-content/uploads/logo-light.png" />
                    </div>

                    <div class="content">
                        <div>
                            <RichText 
                                key="editable" 
                                tagName="h2" 
                                class="title"
                                placeholder="Title" 
                                value={ title }
                                onChange={ onTitleChange }
                                style={ { color: '#fff' } } />
                            <RichText 
                                key="editable" 
                                tagName="h3" 
                                class="subtitle"
                                placeholder="Subtitle" 
                                value={ subtitle }
                                onChange={ onSubtitleChange }
                                style={ { color: '#fff' } } />
                            <RichText 
                                key="editable" 
                                tagName="p" 
                                class="description"
                                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit." 
                                value={ description }
                                onChange={ onDescriptionChange }
                                style={ { color: '#fff' } } />
                        </div>
                        <div>
                            <RichText 
                                key="editable" 
                                tagName="button" 
                                class="btn btn-secondary fw-bold" 
                                placeholder="POGLEDAJ PONUDU" 
                                value={ viewMore }
                                onChange={ onViewMoreChange } 
                                style={ { color: '#fff' } }
                                />
                        </div>
                    </div>
                </a>
            ]
            );
        },

        save({ attributes }) {

            const {
                link,
                backgroundImage,
                title,
                subtitle,
                description,
                viewMore
            } = attributes;

            return (
                <a href={ link } class="special-modal-card lozad cover-image" data-background-image={ backgroundImage }>
                    <div class="logo-featured">
                        <img class="lozad" alt="Olympic Travel Logo" src="/wp-content/uploads/logo-light.png" />
                    </div>

                    <div class="content">
                        <div>
                            <h2 class="title">{ title }</h2>
                            <h3 class="subtitle">{ subtitle }</h3>
                            <p class="description">{ description }</p>
                        </div>
                        <div>
                            <button class="btn btn-secondary fw-bold">{ viewMore }</button>
                        </div>
                    </div>
                </a>
            );
        },
    });
}