import './blocks/cards/card-page';
import './blocks/cards/card-banner';

import './blocks/containers/container';

import './blocks/content/hero-slider';
import './blocks/content/hero-slide';

import './blocks/content/pages-slider';
import './blocks/content/pages-slide';

import './blocks/content/button-outline';

import './blocks/content/special-popup';
